import React, { useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'

export default ({ defaultValue, name }) => {
  const [address, setAddress] = useState(defaultValue || '')

  const handleChange = (address) => setAddress(address);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleChange}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Location',
              className: 'location-search-input form-control',
              name: name
            })}
          />
          <div className="autocomplete-dropdown-container">
            {suggestions.map(suggestion => {
              const className = suggestion.active ? 'suggestion-item active' : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div {...getSuggestionItemProps(suggestion, { className, style })}>
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}