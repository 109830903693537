import React  from 'react';
import axios from 'axios';
import PubSub from 'pubsub-js';
import FieldGroup from '../../smart/field_group';
import SmartModal from '../../smart/modal';
import { UserContext } from '../../user';
import { toastr } from 'react-redux-toastr';

export default class SignInModal extends SmartModal {
  componentDidMount() {
    PubSub.subscribe(this.props.name, this.receivedMessage);

    if (window.PageData.expect_sign_in) PubSub.publish('Devise.SignIn', { modalTitle: 'Sign In' });
  }

  showSignIn = () => {
    this.setState({ loading: false })
  };

  signIn = (user) => {
    let data = this.getData();

    this.setState({ formErrors: {} });

    axios
      .post('/users/sign_in.json', data)
      .then((response) => {
        this.handleClose();

        user.change(response.data.data);
        window.PageData.user_id = response.data.data.id;

        PubSub.publish(`${this.props.name}.SignedIn`);
        PubSub.publish('userSignedIn', response.data.data);

        if (this.state.data.showMessageModal) PubSub.publish('Message.ShowModal');

        const { stored_location } = window.PageData;
        const { pathname } = window.location;
        if (stored_location && pathname !== '/booking') { window.location.href = stored_location }
      })
      .catch((error) => {
        let formErrors = { password: error.response.data.error };
        this.setState({formErrors});
      });
  };

  handleForgotPassword = (e) => {
    e.preventDefault();
    PubSub.publish('Devise.ResetPassword', { modalTitle: 'Forgot your password?' });
  };

  renderSignInForm = () => {
    if (this.state.loading) return <div className="text-center">Loading....</div>;

    return (
      <div className="form">
        <FieldGroup
          type="text"
          label={false}
          placeholder="Email"
          name="user[email]"
          defaultValue={this.formValue("email")}
          error={this.formError("email")}
        />
        <FieldGroup
          type="password"
          label={false}
          placeholder="Password"
          name="user[password]"
          defaultValue={this.formValue("password")}
          error={this.formError("password")}
        />

        <div className="remember-section clearfix">
          <div className="pull-left">
            <div className="rememberme">
              <label>
                <input name="user[remember_me]" type="hidden" defaultValue={0}/>
                <input className="prepared" type="checkbox" defaultValue={1} name="user[remember_me]"/>
                Remember me
              </label>
            </div>
          </div>
          <div className="pull-right">
            <a onClick={this.handleForgotPassword} href="#">
              Forgot password
            </a>
          </div>
        </div>
      </div>
    )
  };

  openSignUp = () => {
    const { data } = this.state;
    PubSub.publish('Devise.SignUp', { modalTitle: 'Create account', showMessageModal: data.showMessageModal} );
  };

  renderSignInButtons = () => (
    <div className="form">
      <UserContext.Consumer>
        {user => (
          <div className="sbm w305">
            <input type="button" onClick={(e) => this.signIn(user)} name="commit" defaultValue="Sign in"/>
          </div>
        )}
      </UserContext.Consumer>
      <div>
        New to SchoolHire?
        <div className="sbm w305">
          <button className="sign-up-btn" onClick={this.openSignUp}>
            Create your SchoolHire account
          </button>
        </div>
      </div>
    </div>
  );
}
