import React from 'react';
import Select from 'react-select';

export default ({ activities, activity, onChange }) => (
  <div className="section">
    <h4>Choose activity</h4>

    <Select
      name="form-field-name"
      value={activity}
      onChange={onChange}
      getOptionLabel={(option) => option.internal_reference }
      getOptionValue={(option) => option.id }
      clearable={false}
      options={activities}
    />
  </div>
);