import React, { useState, useEffect, useMemo } from 'react';
import PubSub from 'pubsub-js';
import { Alert, Button, Modal } from "react-bootstrap";
import axios from "axios";
import Activities from "../booking/activities";
import BookingOptions from "../booking/booking_options";
import CalendarWrapper from "../booking/calendar_wrapper";
import Loading from './Loading';


export default ({ bookingItemId, setBookingItemId }) => {
  const [show, setShow] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [activity, setActivity] = useState(null);
  const [bookingOption, setBookingOption] = useState(null);
  const [bookingOptionItem, setBookingOptionItem] = useState(null);
  const [bookingOptionId, setBookingOptionId] = useState(null);
  const [scheduleItemIds, setScheduleItemIds] = useState([]);
  const [bookingOptionItemId, setBookingOptionItemId] = useState([]);
  const [eventDates, setEventDates] = useState([]);

  const handleClose = () => setBookingItemId(null);

  const datesLength = useMemo(() => eventDates.length, [eventDates]);

  useEffect(() => {
    if (bookingItemId) {
      setShow(true);
      setLoading(true);

      axios.get(`/admin/booking_items/${bookingItemId}/edit.json`).then(response => {
        const bookingItem = response.data.data;

        const { activity_categories, activities } = response.data.included.venue.data;

        const activityCategory = activity_categories.find((activityCategory) => {
          return activityCategory.id === bookingItem.activity_category_id
        });

        const activity = activities.find((item) => item.id === bookingItem.activity_id);

        const filteredActivities = function() {
          if (!activityCategory) return activities;
          return activities.filter(activity => activity.activity_category_id === activityCategory.id);
        }();

        const bookingOptionItemId = bookingItem.booking_option_item_id;
        const eventDates = bookingItem.schedule_items.map(item => item.event_date);

        setActivity(activity);
        setBookingOptionId(bookingItem.booking_option_id);
        setBookingOptionItemId(bookingOptionItemId);
        setEventDates(eventDates);
        setFilteredActivities(filteredActivities);
        setLoading(false);

        fetchBookingOption(bookingItem.booking_option_id, () => {})
      })
    } else {
      setShow(false);
      setFormErrors({});
      setLoading(false);
    }
  }, [bookingItemId]);

  const handleConfirm = (e) => {
    e.preventDefault();

    setFormErrors({});

    axios
      .put(`/admin/booking_items/${bookingItemId}.json`, {
        booking_item: {
          activity_id: (activity || {}).id,
          booking_option_id: (bookingOption || {}).id,
          booking_option_item_id: (bookingOptionItem || {}).id,
          dates: eventDates,
          schedule_item_ids: scheduleItemIds
        }
      })
      .then(() => {
        handleClose();
        PubSub.publish('BookingItem.Updated', { id: bookingItemId })
      })
      .catch((error) => {
        setFormErrors(error.response.data.errors);
      });
  };

  const fetchActivity = (activityId) => {
    axios
      .get(`/activities/${activityId}.json`)
      .then(response => setActivity(response.data.data));
  }

  const fetchBookingOption = (bookingOptionId, onSuccess) => {
    if (isNaN(bookingOptionId)) return null;

    axios
      .get(`/booking_options/${bookingOptionId}.json?booking_item_id=${bookingItemId}`)
      .then(response => {
        setBookingOption(response.data);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(e => console.log(e));
  }

  const changeActivity = (activity) => {
    setActivity(activity);
    setBookingOption(null);

    fetchActivity(activity.id);
  };

  const changeBookingOption = (bookingOptionId) => {
    setBookingOptionId(bookingOptionId);
    setBookingOption(null);

    fetchBookingOption(bookingOptionId, () => {});
  };

  const handleUpdateBookingOptionItem = (bookingOptionItem, dates, scheduleItemIds) => {
    setBookingOptionItem(bookingOptionItem);
    setEventDates(dates);
    setScheduleItemIds(scheduleItemIds);
  };

  const formError = (name) => {
    if (formErrors === null) return null;
    if (typeof formErrors !== 'object') return null;
    return (name in formErrors) ? formErrors[name] : null;
  }

  const datesErrors = formError('dates');

  return (
    <Modal show={show} onHide={handleClose} className='bookingitem-edit'>
      <Modal.Header closeButton>
        <Modal.Title>Edit booking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading}>
          <div className="generator-panel">
            <Activities
              activities={filteredActivities}
              onChange={changeActivity}
              activity={activity}
            />

            {activity &&
              <>
                <BookingOptions
                  bookingOptions={activity.booking_options.data}
                  selectedBookingOptionId={bookingOptionId}
                  onChange={changeBookingOption}
                />

                {bookingOption &&
                  <CalendarWrapper
                    bookingOption={bookingOption}
                    selectedBookingOptionItemId={bookingOptionItemId}
                    selectedEventDates={eventDates}
                    updateBookingOptionItem={handleUpdateBookingOptionItem}
                  />
                }

                {datesErrors &&
                  <Alert bsStyle="danger">
                    {datesErrors.map((error) => <div>{error}</div>)}
                  </Alert>
                }
              </>
            }
          </div>
        </Loading>
      </Modal.Body>
      <Modal.Footer>
        {show &&
          <div>
            <Button className='btn btn-default' onClick={handleClose}>Cancel</Button>
            <Button className='btn btn-primary' disabled={datesLength === 0} onClick={handleConfirm}>Confirm</Button>
          </div>
        }
      </Modal.Footer>
    </Modal>
  );
}
